// extracted by mini-css-extract-plugin
export var home = "home-module--home--kLhIb";
export var divparent = "home-module--divparent--1qbBi";
export var divall = "home-module--divall--1ElTz";
export var divlogo = "home-module--divlogo--3jw-4";
export var menu = "home-module--menu--3DXOU";
export var add = "home-module--add--26Dmz";
export var parentcheckBoxImg2 = "home-module--parentcheckBoxImg2--2FZCZ";
export var checkBoxImg2 = "home-module--checkBoxImg2--1fUou";
export var imgclassName2 = "home-module--imgclassName2--2d1W3";
export var userimage = "home-module--userimage--2PszR";
export var checkBoxImg = "home-module--checkBoxImg--21HKz";
export var parentcheckBoxImg = "home-module--parentcheckBoxImg--XxkyO";
export var imgclassName = "home-module--imgclassName--dUxym";
export var divparentcenter = "home-module--divparentcenter--2Ud-y";
export var graycheckbox = "home-module--graycheckbox--1Cdjj";
export var profilebox = "home-module--profilebox--3JmQu";
export var BtnContainer = "home-module--BtnContainer--3oKLP";
export var cancelBtn = "home-module--cancelBtn--2KIP8";
export var confirmBtn = "home-module--confirmBtn--39rk0";
export var selectProfileicon = "home-module--selectProfileicon--15T5F";
export var ptagforIcon = "home-module--ptagforIcon--26tyH";
export var subjectBtn = "home-module--subjectBtn--MJVJ8";
export var greencancelBtn = "home-module--greencancelBtn--3XtNz";
export var greenconfirmBtn = "home-module--greenconfirmBtn--1Bhp8";
export var mobile = "home-module--mobile--kwchu";