import React, { useState, useEffect, useRef } from "react"
import * as styles from "./mobileCheck.module.css"
import { getMobileConfirm, mobileConfirm } from "utils/mobileUtils.js"
import { useRefObj, useTimer } from "hooks"
const MobileCheck = props => {
  const btnObj = {
    className: props.setting ? "greybtn" : styles.btn,
    text: props.setting ? "확인" : "인증하기",
    width: { width: props.button !== false ? "" : "100%" },
  }

  const [ErrMsg, setErrMsg] = useState("")

  const [mobileRefs, setMobileRefs] = useRefObj({
    mobileTime: "",
    mainInput: "",
  })

  const [inputWidth, setinputWidth] = useState()

  useEffect(() => {
    setinputWidth(
      mobileRefs.current.mainInput.getBoundingClientRect().width - 40
    )
  }, [mobileRefs.current.mainInput])

  const [time, setTime, timeOut] = useTimer("")

  console.log(timeOut)

  const getMobileResult = async () => {
    const result = await mobileConfirm(setErrMsg, setTime)
    if (props.setMobile) props.setMobile(result)
  }

  useEffect(() => {
    if (timeOut) {
      setErrMsg(
        <div>
          인증번호 입력 시간이 초과되었습니다. <br />
          인증번호를 다시 받아주세요 :&#40;
        </div>
      )
    }
  }, [timeOut])

  return (
    <div className={`${styles.box} inputContainer`}>
      <div className={props.divClassName}>
        <input
          type="text"
          placeholder="휴대폰 번호 입력"
          name="mobile"
          className={props.className}
          style={props.inputStyle}
        />
        <div
          className={styles.btn}
          onClick={() => {
            props.button !== false
              ? getMobileConfirm(setErrMsg, setTime)
              : getMobileConfirm()
          }}
          style={{
            fontWeight: "400",
          }}
        >
          인증번호 받기
        </div>
      </div>
      <div className={props.divClassName} style={{ fontWeight: "400" }}>
        <input
          type="password"
          placeholder="인증번호 입력"
          name="mobile"
          className={props.className}
          style={btnObj.width}
          id="mainInput"
          ref={setMobileRefs}
        />

        {props.button !== false ? (
          <div
            className={btnObj.className}
            onClick={timeOut || time === "일치" ? null : getMobileResult}
          >
            {btnObj.text}
          </div>
        ) : null}
        <div
          className={styles.timer}
          id="mobileTime"
          ref={setMobileRefs}
          style={{
            marginLeft: inputWidth,
            color: timeOut ? "#e95c63" : "#0b4247",
          }}
        >
          <div onClick={() => mobileRefs.current?.mainInput?.focus()}>
            {getText(time)}
          </div>
        </div>
      </div>

      <div>
        <div className="errMsg" name="mobile" style={{ fontWeight: "500" }}>
          {ErrMsg}
        </div>
      </div>
    </div>
  )
}

export default MobileCheck

const getText = time => {
  if (typeof time !== "number") {
    return time
  } else {
    const min = Math.floor(time / 60)
    const sec = time % 60
    return `0${min}:${sec < 10 ? `0${sec}` : sec}`
  }
}
