// extracted by mini-css-extract-plugin
export var register = "register-module--register--3koRb";
export var input_ = "register-module--input_--1Zyqu";
export var register2 = "register-module--register2--kR5dx";
export var addregister1 = "register-module--addregister1--BzuhH";
export var subjectbox = "register-module--subjectbox--3bYwo";
export var subjectboxlast = "register-module--subjectboxlast--3uRnN";
export var registersubject = "register-module--registersubject--3GQbp";
export var box = "register-module--box--2WTqc";
export var divchild = "register-module--divchild--3eJ8b";
export var divchildchild = "register-module--divchildchild--3ojeE";
export var selectProfileicon = "register-module--selectProfileicon--1X48R";
export var parentcheckBoxImg2 = "register-module--parentcheckBoxImg2--16cH3";
export var checkBoxImg2 = "register-module--checkBoxImg2--2e7f6";
export var saveBtn = "register-module--saveBtn--2yFUG";
export var unsaveBtn = "register-module--unsaveBtn--1PK14";
export var textbox = "register-module--textbox--3ghyI";
export var selectedCheckBox = "register-module--selectedCheckBox--RxXxc";
export var parentcheckBoxImg = "register-module--parentcheckBoxImg--OLTbP";
export var checkBoxImg = "register-module--checkBoxImg--2h0rd";
export var imgclassName = "register-module--imgclassName--mYS8C";