import React from "react"
import * as styles from "./twoBtnModal.module.css"

const TwoBtnModal = props => {
  const containerStyle = {
    width: `${props.size.w}px`,
    height: `${props.size.h}px`,
  }
  const { btn_1, btn_2 } = props.className ?? {}

  return (
    <div className={styles.main} style={containerStyle}>
      {props.src ? (
        <img src={props.src} style={{ width: "59px", height: "74px" }} />
      ) : null}
      <header>{props.title}</header>
      <main>{props.children}</main>
      <footer>
        <div
          style={{
            display: "flex",
            placeContent: "center",
          }}
        >
          <div
            className={`confirmBtn ${btn_1}`}
            style={{ marginRight: "60.5px" }}
            onClick={props.onConfirm}
          >
            {props.confirm}
          </div>
          <div className={`cancelBtn ${btn_2}`} onClick={props.close}>
            {props.cancel}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default TwoBtnModal
