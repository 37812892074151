import React, { useRef } from "react"
import * as styles from "components/member/profile/register.module.css"

const SquareboxRegister = ({
  index,
  src,
  className,
  onclick,
  subclassName,
  text,
  imgclassName,
  boolean,
  subject,
  classId,
  setIsClicked,
  setClickedKey,
}) => {
  const clicked = ({ target }) => {
    const current = ref?.current ?? target
    const result = setIsClicked(current)
    if (current.className === styles.selectedCheckBox) {
      current.className = subclassName
    } else {
      result.className = subclassName
      current.className = styles.selectedCheckBox
    }
  }

  const ref = useRef()

  const onclick_ = () => {
    console.log(222)
    if (onclick) {
      onclick(src, text, boolean?.includes(text), subject)
    }
    if (setClickedKey) {
      const result = setClickedKey(index)
    }
  }

  const onCheck = () => {
    onclick_()
    if (boolean) {
      clicked(ref.current)
    }
  }

  return (
    <>
      {src ? (
        <>
          {subclassName == undefined ? (
            <div onClick={onclick_} className={className}>
              <img
                src={src}
                alt="peachLogo"
                className={imgclassName}
                onClick={onclick_}
              />
              <p className={styles.textbox} onClick={onclick_}>
                {text}
              </p>
            </div>
          ) : (
            <div className={className} onClick={onCheck}>
              <img src={src} alt="peachLogo" className={imgclassName} />
              <p className={styles.textbox}>{text}</p>
              <div
                ref={ref}
                className={
                  boolean?.includes(text)
                    ? styles.selectedCheckBox
                    : subclassName
                }
                id={classId}
                style={boolean?.includes(text) ? { opacity: "1" } : null}
              />
            </div>
          )}
        </>
      ) : (
        <div onClick={onclick_} className={className}></div>
      )}
    </>
  )
}

export default SquareboxRegister
