import React, { useContext, useState, useEffect, useCallback } from "react"
import SquareboxRegister from "components/member/profile/squareboxRegister"
import whiteplusbtn from "images/whiteplusbtn.png"
import { navigate } from "gatsby"
import * as styles from "pages/member/profile/home.module.css"
import * as styles2 from "components/member/profile/register.module.css"
import modalContext from "context/modalContext"
import TwoBtnModal from "components/tools/modalComponents/twoBtnModal"
import Logo from "components/tools/logo.js"
import api from "apis"
import { useSelector, shallowEqual } from "react-redux"
import Alert from "images/alert.png"
import queryString from "query-string"
import { profiles, topics } from "utils/data.js"
import ProfileText from "pages/member/profile/profileText"

import { setContextError } from "store/modules/errorReducer.js"

import TwoBtnModalContainer from "containers/member/profile/twoBtnModalContainer"
import { getIsApp } from "utils"
const Home = props => {
  const [manageProfile, setManageProfile] = useState(false)
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [profileData, setProfileData] = useState()
  const [isLogin, setIsLogin] = useState(false)
  const [hasProfile, setHasProfile] = useState(false)

  const query = queryString.parse(props.location.search)

  const state = props.location.state

  const isCurrentUser = state?.isCurrentUser

  useEffect(async () => {
    // 인밸리드 핑거 에러발생한경우
    if (isCurrentUser && isCurrentUser !== true) {
      setIsLogin(false)
      setContextError(isCurrentUser)
      showWarning1()
    } else {
      //아닌경우

      setIsLogin(true)

      if (query?.option === "revise") manageProfile_()
    }
  }, [])

  useEffect(async () => {
    if (isLogin) await checkingProfile()
  }, [isLogin])

  const revisedata = useSelector(
    ({ ProfileReducer }) => ProfileReducer.Profile[0],
    shallowEqual
  )

  //   const getAddPost = () => {
  //     const addPost = []

  //     [맵으로 지정한 토픽리스트].forEach((elem, idx) => {
  //       if (데이터.topis[idx] & (1 << idx)) addPost.push(elem)

  //     })
  //     return addPost
  //   }

  //  ))

  const checkingProfile = async () => {
    // let profile;
    // if(getJson(profile)){
    //   profile = await api.readProfile()
    // }
    const profile = await api.readProfile()
    if (!profile) {
      setHasProfile(false)
    } else {
      setHasProfile(true)
      setProfileData(profile)
    }
    return profile
  }

  const showWarning1 = () => {
    setmodalOpen(true)

    setModalMain(
      <TwoBtnModal
        onConfirm={() => {
          showWarning3()
        }}
        close={() => {
          setmodalOpen(false)
          navigate("/login")
        }}
        size={{
          w: 540,
          h: 360,
        }}
        src={Alert}
        title="다른 기기에서 피치서가가 실행중이에요."
        confirm="기기변경"
        cancel="취소"
        className={{
          btn_1: styles.greenconfirmBtn,
          btn_2: styles.greencancelBtn,
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              color: "#7C7C7C",
              textAlignLast: "center",
            }}
          >
            다른 기기에서 피치서가를 실행중인 사용자가 회원님이 아니라면
            <br />
            <span style={{ color: "#E95C63" }}>“기기변경”</span>를 클릭해주세요.
          </p>
        </div>
      </TwoBtnModal>
    )
  }

  const showWarning3 = () => {
    setmodalOpen(true)

    //E03 - 피치서가 - 프로필 - 경고03
    setModalMain(
      <TwoBtnModalContainer
        account_id={props.location.state.account_id}
        account_finger={props.location.state.account_finger}
        account_password={props.location.state.account_password}
        setIsLogin={setIsLogin}
      />
    )
  }

  const register = () => {
    const i = true

    //true - 사용자가 이미지가 있는것, false 는 없는것

    if (i) {
      navigate("/member/profile/registerProfile")
    } else {
    }
  }

  const manageAccount_ = () => {
    navigate("/member/setting/SettingAccount")
  }

  const manageProfile_ = () => {
    manageProfile ? setManageProfile(false) : setManageProfile(true)
  }

  const goTomanageProfile_ = () => {
    navigate("/member/profile/manageProfile")
  }
  // const profileData = useSelector(
  //   ({ ProfileReducer }) => ProfileReducer.Profile[0],
  //   shallowEqual
  // )

  const SaveRevisedProfile_ = async () => {
    console.log(revisedata)

    if (revisedata == undefined) {
      return
    }

    if (revisedata["email"]) {
      const result = await api.reviseEmail({
        body: { email: revisedata["email"] },
      })
    }

    if (revisedata["icon"]) {
      const result = await api.reviseIcon({
        body: { icon: revisedata["icon"] },
      })
    }

    if (revisedata["name"]) {
      const result = await api.reviseName({
        body: { name: revisedata["name"] },
      })
    }

    if (revisedata["number"]) {
      const result = await api.revisePhone({
        body: {
          phone: {
            number: revisedata["number"],
            code: revisedata["code"],
            timestamp: revisedata["timestamp"],
          },
        },
      })
    }

    if (revisedata["topics"]) {
      const myTopics = revisedata["topics"].join("#")
      const result = await api.reviseSubject({
        body: { topics: myTopics },
      })
      console.log(result)
    }

    await checkingProfile()
    // navigate("/member/profile/home")

    manageProfile_()
    // checkingProfile();
  }

  const goToMainPage_ = () => {
    getIsApp() ? navigate("/front") : navigate("/member/success/login")
  }

  const csCenter_ = () => {
    navigate("/cs/list")
  }

  return (
    <div className={styles.home}>
      <div className={`${styles.divparent} ${styles.divall}`}>
        <div className={styles.divlogo}>
          <Logo w={188} h={41} white={true} navCancel={true} />
        </div>
        {hasProfile ? (
          <div style={{ display: "flex" }} className={styles.menu}>
            <p onClick={manageProfile_}>프로필 수정</p>
            <p onClick={manageAccount_}>회원 정보</p>
            <p onClick={csCenter_}>고객센터</p>
          </div>
        ) : null}
      </div>

      <div style={{ marginTop: 130 }}>
        <ProfileText
          state={manageProfile}
          revise={query?.option}
          hasProfile={hasProfile}
          name={profileData?.name}
          manageProfile={manageProfile}
        ></ProfileText>
      </div>
      <div className={styles.userimage}>
        {profileData ? (
          <>
            {manageProfile ? (
              <>
                <SquareboxRegister
                  src={profiles.get(profileData["icon_no"])}
                  onclick={goTomanageProfile_}
                  className={styles.parentcheckBoxImg2}
                  subclassName={styles.checkBoxImg2}
                  imgclassName={styles.imgclassName2}
                  text={"수정"}
                />
                <p
                  style={{
                    padding: "0",
                    color: "white",
                    fontSize: "34px",
                    fontFamily: "RIDIBatang",
                  }}
                >
                  {profileData["name"]}
                </p>
                <div style={{ marginTop: "93px" }}>
                  <button
                    className={styles2.saveBtn}
                    style={{ margin: "0" }}
                    onClick={SaveRevisedProfile_}
                  >
                    저장
                  </button>
                </div>
              </>
            ) : (
              <>
                <SquareboxRegister
                  src={profiles.get(profileData["icon_no"])}
                  onclick={goToMainPage_}
                  className={styles.parentcheckBoxImg2}
                  imgclassName={styles.imgclassName2}
                />
                <p
                  style={{
                    padding: "0",
                    color: "white",
                    fontSize: "34px",
                    fontFamily: "RIDIBatang",
                  }}
                >
                  {profileData["name"]}
                </p>
              </>
            )}
          </>
        ) : (
          <>
            <SquareboxRegister
              src={whiteplusbtn}
              className={styles.add}
              onclick={register}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Home
