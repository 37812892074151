import React from "react"
import * as styles from "pages/member/profile/home.module.css"

const ProfileText = props => {
  let text
  console.log(props.revise)
  if (props.state && !(props.revise === "revise")) {
    text = "프로필을 수정하시겠어요?"
  } else if (props.hasProfile) {
    return (
      <div className={styles.divall} style={{ justifyContent: "center" }}></div>
    )
  } else {
    text = "나만의 수업준비를 위해 프로필을 만드세요."
  }

  return (
    <div className={styles.divall} style={{ justifyContent: "center" }}>
      <p style={{ color: "white" }}>{text}</p>
    </div>
  )
}

export default ProfileText
