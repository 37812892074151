import TwoBtnModal from "components/tools/modalComponents/twoBtnModal"
import React, { useEffect, useState, useContext } from "react"
import MobileCheck from "components/tools/mobileCheck.js"
import modalContext from "context/modalContext"
import Alert from "images/alert.png"
import * as styles from "pages/member/profile/home.module.css"
import api from "apis"
import { login } from "utils/memberUtils"
import { navigate } from "gatsby"

const TwoBtnModalContainer = props => {
  const [currentUser, setCurrentUser] = useState(false)
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [isRegister, setIsRegister] = useState()
  useEffect(() => {
    setCurrentUser(props.currentUser)
  }, [props.currentUser])

  const setMobile = async uuid => {
    const data = {
      body: {
        account_id: props.account_id,
        account_finger: props.account_finger,
        phone_uuid: uuid?.uuid,
      },
    }

    const result = await api.setCurrentUser(data)
    if (result) {
      if (result.data.desc === "not registered phone") {
        setIsRegister(false)
      } else {
        setCurrentUser(true)
        setIsRegister(true)
      }
    }
  }

  const checkCurrentUser = async () => {
    if (!isRegister) {
      alert("등록된 휴대폰 번호가 아닙니다. 다시 시도해주세요")
    }
    console.log(3939, currentUser)
    if (currentUser) {
      await login(props.account_id, props.account_password)
      props.setIsLogin(true)
      setmodalOpen(false)
    }
  }

  return (
    <TwoBtnModal
      onConfirm={checkCurrentUser}
      close={() => {
        setmodalOpen(false)
        navigate("/login")
      }}
      size={{
        w: 540,
        h: 450,
      }}
      src={Alert}
      title="새로운 기기에서 피치서가를 실행합니다."
      confirm="시작하기"
      cancel="나가기"
      className={{
        btn_1: styles.greenconfirmBtn,
        btn_2: styles.greencancelBtn,
      }}
    >
      <div>
        <p
          style={{
            fontSize: "16px",
            color: "#7C7C7C",
            textAlignLast: "center",
          }}
        >
          피치서가 이용을 위해 본인인증을 해주세요.
        </p>

        <div className={styles.mobile}>
          <MobileCheck setMobile={setMobile} />
        </div>
      </div>
    </TwoBtnModal>
  )
}

export default TwoBtnModalContainer
