import { navigate } from "gatsby"
import React from "react"
import * as styles from "./logo.module.css"
import { logoBK, logoWH } from "images"

const Logo = props => {
  return (
    <div
      className={styles.con}
      onClick={() => {
        if (!props.navCancel) navigate("/front/")
      }}
    >
      <img
        src={props.white ? logoWH : logoBK}
        style={
          props.customStyle
            ? props.customStyle
            : { width: props.w, height: props.h }
        }
      />
    </div>
  )
}

export default Logo
